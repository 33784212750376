import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { signUp } from "../../../helpers/Firebase";


const ContactForm = () => {
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .required("Email is required.")
      .email("Entered value does not match email format."),
    password: Yup.string().required("A Password is required to create your account."),
    businessName: Yup.string().required("We need your business name to create an account.")
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data, e) {
    // display form data on success
    signUp(data);
  }

  return (
    <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="messages"></div>
      <div className="row controls">
        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>Name</label>
            <input
              type="text"
              placeholder="Name"
              name="name"
              {...register("name")}
              className={`${errors.name ? "is-invalid" : ""}`}
            />
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name?.message}
              </div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>Business Name</label>
            <input
              placeholder="Business Name"
              name="businessName"
              type="text"
              {...register("businessName")}
              className={` ${errors.businessName ? "is-invalid" : ""}`}
            />
            {errors.businessName && (
              <div className="invalid-feedback">{errors.businessName?.message}</div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>Email Address</label>
            <input
              placeholder="Enter Your Email"
              name="email"
              type="text"
              {...register("email")}
              className={` ${errors.email ? "is-invalid" : ""}`}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email?.message}</div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>Password</label>
            <input
              placeholder="Create Your Passwrd"
              name="password"
              type="text"
              {...register("password")}
              className={` ${errors.password ? "is-invalid" : ""}`}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password?.message}</div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-12">
          <button type="submit" className="theme-btn-one">
            Create Your Account & Grow Your Business
          </button>
        </div>
        {/* End .col */}
      </div>
    </form>
  );
};

export default ContactForm;
