import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Network Library
import axios from 'axios';

// Needed for Firebase Functions
import "firebase/functions";

// UX libraries
import validator from 'validator';
import toast from 'react-simple-toasts';

// Utitlies
import getCookie from './Utilities'
  
// Config Details for Firebase App
const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIRE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIRE_DB_URL}`,
    projectId: `${process.env.REACT_APP_FIRE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIRE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIRE_MESSAGE_SENDER}`,
    appId: `${process.env.REACT_APP_FIRE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIRE_MEASUREMENT_ID}`
  };

// Initialize Firebase App & Options
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();


var sent = false;

// Email list
function basicSignUp(input) {
  if (inputValidatorEmail(input)) {
    if (!sent) {
      db.collection("email_list").add({
        email: input
      })
      .then((docRef) => {
          toast("You're awesome! We will reach out with news, updates, & exclusive offers!");
          sent = true;
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
          toast("Whoops, it doesn't look like that's a valid email. Please try again.");
      });
    }
  }
}

// Initial Sign up
function signUp(data) {
  if (data.email && data.password) {
    if (localStorage.getItem("uid")) {
      handleSiteUrlCreation(data.siteName, localStorage.getItem("uid"));
    } else {
      // Attempt to Register User's Credentials
      auth.createUserWithEmailAndPassword(data.email, data.password)
      .then(async (userCredential) => {
        // User's UUID (Murkt ID)
        const user = userCredential.user;

        // Register User's Information
        await db.collection("users_v1").doc(user.uid).set({
          uuid: user.uid,
          name: data.name,
          email: data.email,
          businessName: data.businessName
        })

        // Set Cookie for User's ID
        localStorage.setItem("uid", user.uid);
      })
      .catch((error) => {
        // Errors from Authentication
        console.log(error.message);
        console.log(error.code);
        toast("Whoops, it looks like that email already exists. Please try with another email.");
      });
    }
  }
}

function handleSiteUrlCreation(site, uid) {
  // Validate the URL's Availability Before Creating URL
  let url = validateUrl(site);
  url.then(function(res) {
    if (!res) {
      toast("Whoops, this URL is already taken. Please try another one.");
    } else {
      // COMING SOON
      window.location.href = '/coming-soon';
      // createUrl(site, uid);
    }
  });
}

// Business Sign Up
function signUpBusiness(state, data) {
  const uid = getCookie("uid");
  db.collection("users_v1").doc(uid).update({
    address: state.address,
    address_2: data.address_2 || '',
    city: state.city,
    state: state.state,
    zip: state.zip,
    phone: data.phone
  })
  .then(() => {
    console.log('Business Added');
  })
  .catch((error) => {
    console.log(error.message);
  })
}

// Sign in
function signIn(data) {
  if (data.email && data.password) {
    auth.signInWithEmailAndPassword(data.email, data.password)
    .then((userCredential) => {
      retrieveAccountDetails(userCredential.user.uid);
    })
    .catch((error) => {
      console.log(error.message);
    })
  }
}

// Get account details based on user's UID
function retrieveAccountDetails(uid) {
  if (uid) {
    db.collection("users_v1").doc(uid).get().then((doc) => {
      if (doc.exists) {
        console.log("Document data: ", doc.data());
        console.log(doc.data().name);
        console.log(doc.data().business);
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document: ", error);
    })
  }
}

// Validate if email is formatted correctly
function inputValidatorEmail(input) {
  if (input) {
    if (validator.isEmail(input)) {
      return true;
    }
  }
  return false;
}

// Validate if URL scheme is valid for site, MUST use this as an async function
async function validateUrl(url) {
  return await db.collection("urls").doc(url).get().then((doc) => {
    // Means this URL exists in our DB already
    if (doc.exists) {
      return false;
    }
    return true;
  })
}

// Crate new URL scheme for a new site
function createUrl(url, uuid) {
  db.collection("urls").doc(url).set({
    uuid: uuid
  })
  .then((res) => {
    window.location.href = '/signup-calendar';
  })
  .catch((error) => {
    console.log("Error setting document: ", error);
  })
}
  
export {basicSignUp, signUp, signIn, signUpBusiness}; 