import React from "react";
import { Link } from "react-router-dom";

const MegaMenu = () => {
  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown position-static active">
        <a className="nav-link" href="#" data-toggle="dropdown">

        </a>
      </li>
    </ul>
  );
};

export default MegaMenu;
