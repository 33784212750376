import React from "react";

const FeatureContent = [
  {
    icon: "calendar-check-solid",
    title: "Flexible Payments",
    subTitle: `Choose between 3, 6, or 12 months of payments`,
    bgColor: "#fff",
    delayAnimation: "",
  },
  {
    icon: "square-check-solid",
    title: "No Credit Inquiry",
    subTitle: `We do a soft credit check to prequalify your loan`,
    bgColor: "#fff",
    delayAnimation: "200",
  },
  {
    icon: "thumbs-up-solid",
    title: "Quick Approval",
    subTitle: `Know within minutes if your repair is covered`,
    bgColor: "#fff",
    delayAnimation: "400",
  },
];

const FancyFeatureTewentyThree = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <div className="block-style-twentyTwo">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle}</p>
          </div>
          {/* /.block-style-twentyTwo */}
        </div>
      ))}
    </>
  );
};

export default FancyFeatureTewentyThree;
