import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../components/header/HeaderTwo";
import SignUpForm from "../../components/contact/form/SignUpForm";

const SignUpSimple = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Sign Up for Nowww!
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      <div className="fancy-hero-seven space-fix">
        <div className="container">
        <div className="row">
            <div className="col-xl-9 col-lg-11 col-md-10 m-auto">
            <h2>Start Earning Thousands More!</h2>
            </div>
        </div>
        <div className="form-style-classic mt-60 md-mt-80">
            <SignUpForm />
        </div>
        </div>
      </div>
      <footer style={{backgroundColor: "#1f1f1f"}}>
        <p className="text-center font-rubik copyright-text">
          Copyright @{new Date().getFullYear()}{" "}
          <a
              href="https://nowww.app"
              target="_blank"
              title="copyright"
              rel="noreferrer"
              >
          Nowww
          </a>{" "}
          LLC
        </p>
      </footer>
    </div>
  );
};

export default SignUpSimple;