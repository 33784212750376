import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getGoogleCalendarAuthUrl } from "../../../helpers/GoogleCalendar";
import toast from "react-simple-toasts";

const CalendarChoiceSignUpForm = () => {

  // for validation
  const validationSchema = Yup.object().shape({
    url: Yup.string().required("URL is required"),
  });

  // get functions to build form with useForm() hook
  const { handleSubmit, formState } = useForm();
  const { errors } = formState;

  function onSubmit(data, e) {
    getGoogleCalendarAuthUrl();
    // const url = getGoogleCalendarAuthUrl();
    // url.then(function(res) {

    // });
  }

  return (
    <>
      <form className="user-data-form " onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <button type="submit" className="theme-btn-one mt-30 mb-50">
              Google Calendar
            </button>
            <button type="button" className="theme-btn-one mt-30 mb-50">
              Outlook Calendar
            </button>
            <button type="button" className="theme-btn-one mt-30 mb-50">
              Microsoft Calendar
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>
    </>
  );
};

export default CalendarChoiceSignUpForm;
