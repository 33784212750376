import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// import { validateUrl, createUrl } from "../../../helpers/Firebase";
import toast from "react-simple-toasts";

const SiteSignUpForm = () => {

  // for validation
  const validationSchema = Yup.object().shape({
    url: Yup.string().required("URL is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data, e) {
    // let url = validateUrl(data.url);
    // url.then(function(res) {
    //     if (!res) {
    //       toast("Whoops, this URL is already taken. Please try another one.");
    //     } else {
    //       createUrl(data.url);
    //     }
    // });
  }

  return (
    <>
      <form className="user-data-form " onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="input-group-meta mb-50">
              <label>Site URL</label>
              <input
                type="text"
                placeholder="Enter Desired URL"
                name="url"
                {...register("url")}
                className={` ${errors.url ? "is-invalid" : ""}`}
              />

              {errors.url && (
                <div className="invalid-feedback">{errors.url?.message}</div>
              )}
            </div>
          </div>
          
          <div className="col-12">
            <button type="submit" className="theme-btn-one mt-30 mb-50">
              Register Site
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>
    </>
  );
};

export default SiteSignUpForm;
