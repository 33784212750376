// React class
import React from "react";

// Utility tools
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useState } from 'react';

// FireStore (db)
// import { basicSignUp } from "../../helpers/Firebase";

const socialContent = [
  // {
  //   icon: "fa-twitter",
  //   link: "https://twitter.com/MurktApp",
  // },
  // {
  //   icon: "fa-instagram",
  //   link: "https://www.instagram.com/murktapp/",
  // },
  // {
  //   icon: "fa-linkedin",
  //   link: "https://www.linkedin.com/company/murkt/",
  // },
];

const ComingSoon = () => {
  const [input, setInput] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    // basicSignUp(input);
  };
  return (
    <>
      <Helmet>
        <title>
          Nowww | Auto Repair Financing for Anyone
        </title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="full-height-layout d-flex align-items-center" style={{backgroundColor: "#1f1f1f"}}>
        <div className="coming-soon-content font-gordita">
          <div className="logo coming-soon-brand">
            <Link to="/">
              <img src="images/logo/Nowww-white.png" alt="brand logo" />
            </Link>
          </div>
          <h6>COMING SOON</h6>
          <h1 data-aos="fade-up">Thanks for your interest, we're almost ready to launch!</h1>
          <div className="row">
            <div className="col-lg-9 m-auto">
              <p>
                Check back in a few weeks to to finance your next auto repair intest free.
              </p>
            </div>
          </div>
          {/* <form onClick={handleSubmit}>
            <input type="email" placeholder="mybusiness@mgoogle.com" value={input} onInput={e => setInput(e.target.value)}/>
            <button>Notify Me</button>
          </form> */}
        </div>
        {/* /.coming-soon-content */}
      </div>
    </>
  );
};

export default ComingSoon;
