import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpBusiness } from "../../../helpers/Firebase";
import * as Yup from "yup";
import "yup-phone";

let autoComplete;

const BusinessSignUpForm = () => {
  const [state, setState] = React.useState({
    address: "",
    city: "",
    state: "",
    zip: "",
  })

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
  
    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }
  
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };
  
  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }
  
  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    const theAddress = addressObject.address_components[0].short_name + ' ' + addressObject.address_components[1].short_name
    setState({ address: theAddress,
               city: addressObject.address_components[2].short_name,
               state: addressObject.address_components[4].short_name,
               zip: addressObject.address_components[6].short_name 
    });
  }
  

  // for validation
  // const validationSchema = Yup.object().shape({
  //   address: Yup.string().required("Address is required"),
  //   city: Yup.string().required("City is required"),
  //   state: Yup.string()
  //     .required("State is required"),
  //   zip: Yup.string()
  //     .min(5, "Zip must be at least 5 characters")
  //     .required("Zip Code is required"),
  //   phone: Yup.string()
  //     .phone("US")
  //     .required("Phone is required"),
  // });

  // const formOptions = { resolver: yupResolver(validationSchema) };
  const formOptions = {};
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data, e) {
    signUpBusiness(state, data);
    e.target.reset();
  }

  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <>
      <form className="user-data-form " onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="input-group-meta mb-50">
              <label>Address</label>
              <input
                type="text"
                ref={autoCompleteRef}
                onChange={event => setQuery(event.target.value)}
                placeholder="Enter Business Address"
                value={query}
                name="address"
                className={` ${errors.address ? "is-invalid" : ""}`}
              />
              {errors.address && (
                <div className="invalid-feedback">{errors.address?.message}</div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-50">
              <label>Address 2 (Optional)</label>
              <input
                type="text"
                id="address_2"
                placeholder="Enter Business Address 2"
                name="address_2"
                {...register("address_2")}
                className={` ${errors.address_2 ? "is-invalid" : ""}`}
              />

              {errors.address && (
                <div className="invalid-feedback">{errors.address?.message}</div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-50">
              <label>City</label>
              <input
                type="text"
                id="city"
                placeholder="Enter Business City"
                name="city"
                value={state.city || ''}
                {...register("city")}
                className={` ${errors.city ? "is-invalid" : ""}`}
              />

              {errors.city && (
                <div className="invalid-feedback">{errors.city?.message}</div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-50">
              <label>State</label>
              <input
                placeholder="Enter Business State"
                name="state"
                type="text"
                value={state.state || ''}
                id="state"
                {...register("state")}
                className={` ${errors.state ? "is-invalid" : ""}`}
              />

              {errors.state && (
                <div className="invalid-feedback">{errors.state?.message}</div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-50">
              <label>Zip Code</label>
              <input
                placeholder="Enter Zip Code"
                name="zip"
                id="zip"
                type="text"
                value={state.zip || ''}
                {...register("zip")}
                className={` ${errors.zip ? "is-invalid" : ""}`}
              />
              {errors.zip && (
                <div className="invalid-feedback">
                  {errors.zip?.message}
                </div>
              )}
              {/* End error msg */}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-50">
              <label>Phone Number</label>
              <input
                placeholder="Enter Business Phone Number"
                name="phone"
                type="text"
                {...register("phone")}
                className={` ${errors.phone ? "is-invalid" : ""}`}
              />
              {errors.phone && (
                <div className="invalid-feedback">
                  {errors.phone?.message}
                </div>
              )}
              {/* End error msg */}
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="theme-btn-one mt-30 mb-50">
              Style Your Site
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>
    </>
  );
};

export default BusinessSignUpForm;
