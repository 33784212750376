import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Murkt | A New Way to Market</title>
        <meta property="og:site_name" content="Murkt" />
        <meta
          property="og:url"
          content="https://murkt.io"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Murkt | A New Way to Market"
        />
        <meta
          name="keywords"
          content="small business software, small business tools, scheduling, website, app, mobile app, marketing, saas, calendar, StyleSeat"
        />
        <meta
          name="description"
          content="Murkt allows small businesses to have a site, app, & be discovered by billions in under 5 minutes of setup."
        />
        <meta name="description" content="Optimzed Website & App to Gain Cusotmers" />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
