import React from "react";

// Home
import Home from "../views/all-home-pages/Home";
import Home1 from "../views/all-home-pages/Home1";
import ComingSoon from "../views/all-home-pages/ComingSoon";

// Authentication
import Login from "../views/inner-pages/Login";

// Registration
import SignUpBusiness from "../views/inner-pages/SignUpBusiness";
import SignUpSite from "../views/inner-pages/SignUpSite";
import SignUpCalendarChoice from "../views/inner-pages/SignUpCalendarChoice";
import SignUpSimple from "../views/inner-pages/SignUpSimple";

// Calendars
import GoogleCalendarDetails from "../views/inner-pages/GoogleCalendarDetails";

// Not Found Page
import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          {/* Home */}
          <Route exact path="/" component={Home1} />
          <Route exact path={"/coming-soon"} component={ComingSoon} />

          {/* Auth & Registration */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUpSimple} />
          <Route exact path="/signup-business" component={SignUpBusiness} />
          <Route exact path="/signup-site" component={SignUpSite} />
          <Route exact path="/signup-calendar" component={SignUpCalendarChoice} />

          {/* Calendars */}
          <Route path="/signup-google-calendar" component={GoogleCalendarDetails} />

          {/* Error Pages   */}
          <Route path="/404" component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
