import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingMobileApp from "../../components/header/landing/HeaderLandingMobileApp";
import CopyRightThree from "../../components/footer/CopyRightThree";
import FancyFeatureTewentyThree from "../../components/features/FancyFeatureTewentyThree";

const MobileAppLanding = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          {" "}
          Nowww | Auto Repair Financing for Anyone
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingMobileApp />
      {/* End Mobile App Landing Header */}

      {/* =============================================
            Theme Hero Banner
        ==============================================  */}
      <div className="hero-banner-ten" id="home">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
              <h1
                className="hero-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Finance Any Auto Repair for FREE
              </h1>
              <p
                className="hero-sub-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                See if you qualify for 0% interest without hurting your credit!
              </p>
            </div>
          </div>
          {/* End .row */}

          <div className="d-sm-flex align-items-center justify-content-center button-group">
            <a
              href="/coming-soon"
              className="d-flex align-items-center download-button"
            >
              <div style={{jusifyContent: "center", alignItems: "center"}}>
                <span>It only takes minutes</span>
                <strong>Prequalify Now</strong>
              </div>
            </a>
          </div>
        </div>
        {/* End .container */}
      </div>
      {/* /.hero-banner-ten */}

      {/* =============================================
				Fancy Feature Twenty Two
			==============================================  */}
      <div
        className="fancy-feature-twentyTwo pt-130 md-mt-80 sm-pt-100"
        id="product"
      >
        <div className="container">
          <div className="title-style-ten mb-40 md-mb-20">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <h2>Get Your Vehicle Fixed Today</h2>
              </div>
              <div className="col-lg-5">
                <p className="md-pt-20">
                  We pay the shop directly so you can get back on the road quicker.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <FancyFeatureTewentyThree />
          </div>
        </div>
      </div>
      {/* /.fancy-feature-twentyTwo */}

      {/* =====================================================
            Fancy Short Banner Twelve
        ===================================================== */}
      <div className="fancy-short-banner-twelve">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-10 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-ten text-center">
                <h2>Car Repairs Now, Pay Later</h2>
                <p className="pt-25 pb-45">
                  Prequalify now risk free — no hard credit check required
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <div className="d-sm-flex align-items-center justify-content-center button-group">
              <a href="/coming-soon" className="d-flex align-items-center download-button">
                <div>
                  <span>It only takes minutes</span>
                  <strong>Prequalify Now</strong>
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-twelve */}

      {/* =====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven">
        <div className="container">
          <div className="bottom-footer">
            <CopyRightThree />
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default MobileAppLanding;