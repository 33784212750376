// Network Library
import axios from 'axios';

function getGoogleCalendarAuthUrl() {
    // Make sure to switch to Prod before development
    axios.get(`${process.env.REACT_APP_FIRE_FUNC_GOOGLE_CALENDAR_AUTH_DEV}`)
    .then(res => {
      console.log(res.data.url);
      window.location.href = res.data.url;
    });
  }

export { getGoogleCalendarAuthUrl };